import { WORDS } from './wordlist';
import { VALID_GUESSES as VALID_GUESSES_3 } from './validGuesses3';
import { VALID_GUESSES as VALID_GUESSES_4 } from './validGuesses4';

const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
const now = Date.now()
const msInDay = 86400000
const index = Math.floor((now - epochMs) / msInDay)
const solution = WORDS[index % WORDS.length].toUpperCase()
const validGuesses = [] as string[]

if(solution.length === 3) {
    validGuesses.push(...VALID_GUESSES_3)
}
if(solution.length === 4) {
    validGuesses.push(...VALID_GUESSES_4)
}
export { validGuesses as  VALID_GUESSES }