import { WORDS } from './wordlist';

const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
const now = Date.now()
const msInDay = 86400000
const index = Math.floor((now - epochMs) / msInDay)
const solution = WORDS[index % WORDS.length].toUpperCase()

export const MAX_WORD_LENGTH = solution.length
export const HINT_COUNT = [0,0,0,10,6,0][MAX_WORD_LENGTH]
export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 350
export const GAME_LOST_INFO_DELAY = (MAX_WORD_LENGTH + 1) * REVEAL_TIME_MS
