export const WORDS = [
  'also',
  'away',
  'back',
  'been',
  'best',
  'bird',
  'body',
  'book',
  'both',
  'came',
  'city',
  'cold',
  'come',
  'does',
  'door',
  'down',
  'draw',
  'each',
  'easy',
  'even',
  'ever',
  'eyes',
  'face',
  'fall',
  'farm',
  'fast',
  'feet',
  'find',
  'fire',
  'fish',
  'five',
  'food',
  'form',
  'four',
  'from',
  'girl',
  'give',
  'good',
  'grow',
  'hand',
  'hard',
  'have',
  'head',
  'hear',
  'help',
  'here',
  'high',
  'hold',
  'home',
  'into',
  'just',
  'keep',
  'kind',
  'king',
  'land',
  'last',
  'late',
  'left',
  'life',
  'like',
  'line',
  'list',
  'live',
  'long',
  'look',
  'made',
  'make',
  'many',
  'many',
  'mark',
  'mile',
  'miss',
  'more',
  'most',
  'move',
  'must',
  'name',
  'near',
  'need',
  'next',
  'once',
  'only',
  'over',
  'page',
  'part',
  'plan',
  'play',
  'read',
  'real',
  'rock',
  'room',
  'said',
  'same',
  'seem',
  'seen',
  'ship',
  'show',
  'side',
  'sing',
  'some',
  'song',
  'soon',
  'step',
  'stop',
  'such',
  'sure',
  'take',
  'than',
  'that',
  'them',
  'then',
  'they',
  'this',
  'time',
  'told',
  'took',
  'town',
  'tree',
  'true',
  'turn',
  'unit',
  'upon',
  'very',
  'walk',
  'want',
  'wave',
  'well',
  'went',
  'were',
  'what',
  'when',
  'wind',
  'with',
  'word',
  'work',
  'your',
  ]
  