export const WORDS = [
  'ace',
  'act',
  'age',
  'ago',
  'air',
  'all',
  'and',
  'ape',
  'are',
  'arm',
  'ask',
  'ate',
  'bad',
  'bag',
  'bat',
  'bed',
  'bee',
  'big',
  'bit',
  'box',
  'boy',
  'bun',
  'bus',
  'but',
  'buy',
  'bye',
  'cab',
  'can',
  'car',
  'cat',
  'cow',
  'cry',
  'cub',
  'cut',
  'dab',
  'dad',
  'dam',
  'day',
  'den',
  'did',
  'dip',
  'dot',
  'dug',
  'ear',
  'eat',
  'egg',
  'elf',
  'end',
  'eye',
  'fan',
  'far',
  'fat',
  'few',
  'fin',
  'fit',
  'fix',
  'fly',
  'for',
  'fox',
  'fry',
  'fun',
  'gas',
  'gel',
  'get',
  'god',
  'got',
  'had',
  'has',
  'hat',
  'hen',
  'her',
  'his',
  'hit',
  'how',
  'ice',
  'ill',
  'ink',
  'jab',
  'jam',
  'jar',
  'jet',
  'job',
  'jog',
  'jug',
  'key',
  'kit',
  'lay',
  'let',
  'lit',
  'lot',
  'mad',
  'man',
  'map',
  'mat',
  'may',
  'met',
  'mix',
  'mom',
  'mud',
  'mug',
  'mum',
  'nap',
  'net',
  'net',
  'new',
  'nod',
  'not',
  'now',
  'nut',
  'oar',
  'odd',
  'old',
  'one',
  'our',
  'out',
  'owl',
  'own',
  'pat',
  'paw',
  'peg',
  'pet',
  'pin',
  'pit',
  'pop',
  'pot',
  'pup',
  'put',
  'rag',
  'ram',
  'rap',
  'rat',
  'row',
  'rub',
  'rug',
  'run',
  'sat',
  'saw',
  'see',
  'set',
  'sir',
  'sit',
  'sob',
  'sow',
  'tan',
  'tap',
  'ten',
  'tip',
  'toe',
  'top',
  'tow',
  'tug',
  'two',
  'use',
  'van',
  'vet',
  'war',
  'was',
  'way',
  'wet',
  'who',
  'why',
  'wig',
  'win',
  'won',
  'wow',
  'yak',
  'yes',
  'yet',
  'you',
  'zap',
  'zip',  
  ]
  